@use 'theme';
@use 'responsive';
@use '~/components/ContentModule/variants/variants';

.formOnly {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  align-items: center;
  position: relative;
  border-radius: 30px;
  overflow: hidden;

  @include theme.apply('background', foreground-subtle);
  @include variants.sizeTheme;

  @include responsive.mobile {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
    padding: 16px;
    row-gap: 48px;
  }

  .slotsWrapper {
    position: relative;
    width: 100%;
    height: 100%;

    @include responsive.gtMobile {
      .imageWrapper {
        position: absolute;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;

        .image {
          height: 100%;
          width: 100%;
          object-fit: cover;
          object-position: left center;
        }
      }
    }
  }

  .image {
    height: 100%;
    aspect-ratio: 4 / 3;
    object-fit: cover;

    @include responsive.mobile {
      height: auto;
      border-radius: 30px;
    }
  }

  .side {
    position: relative;
    width: 100%;
    height: 100%;

    @include responsive.gtMobile {
      @include variants.backgroundTheme;

      &.white {
        @include responsive.mobile {
          background: none;
        }
      }
    }

    @include responsive.mobile {
      height: auto;
    }

    .formWrapper {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px;

      @include responsive.mobile {
        padding: 0;
      }
    }
  }

  &.reverse {
    @include responsive.gtMobile {
      .slotsWrapper .imageWrapper .image {
        object-position: right center;
      }

      > :first-child {
        grid-column: 2;
        grid-row: 1;
      }

      > :nth-child(2) {
        grid-column: 1;
        grid-row: 1;
      }
    }

    @include responsive.mobile {
      > :first-child {
        grid-column: 1;
        grid-row: 2;
      }
    }
  }

  &.reverseMobile {
    @include responsive.mobile {
      > :first-child {
        grid-row: 2;
        grid-column: 1;
      }

      > :nth-child(2) {
        grid-row: 1;
        grid-column: 1;
      }
    }
  }
}
