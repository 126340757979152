@use 'responsive';
@use 'mixins';
@use 'designSystem';
@use '~/components/ContentModule/variants/variants';

.slotHeading {
  @include designSystem.textStyleHeadingLarge42;
  @include variants.textColorTheme;

  margin-bottom: 20px;

  @include mixins.headings {
    @include designSystem.textStyleHeadingLarge42;
  }
}
