@use 'designSystem';
@use 'theme';
@use 'mixins';

.textField {
  width: 100%;
  display: inline-flex;
  flex-direction: column;
  min-width: 0;
  gap: 8px;

  .input {
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    width: 100%;
    min-height: 49px;
    border-radius: 8px;
    outline: none;
    border: 2px solid;
    padding: 12px;
    cursor: text;
    transition: border-color 0.3s;

    @include theme.apply(border-color, foreground-disabled);
    @include theme.apply(background-color, neutral-white);

    &:focus-within {
      @include theme.apply(border-color, foreground-default);
    }

    &.readOnly {
      cursor: default;
    }

    textarea,
    input {
      width: 100%;
      min-width: 0;
      min-height: 25px;
      background: unset;
      border: none;
      -webkit-tap-highlight-color: transparent;
      display: block;
      text-overflow: ellipsis;
      outline: none;
      padding: 0;
      resize: vertical;

      @include designSystem.textStyleBodySmall18;
      @include theme.apply(color, foreground-default);

      &::placeholder {
        @include theme.apply('color', foreground-muted);

        transition: 0.2s all ease;
      }

      &:read-only {
        cursor: default;
      }
    }

    .icon {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      align-self: flex-start;

      @include mixins.svgSize('L');
      @include theme.apply(color, foreground-default);
    }
  }

  &.disabled {
    .input {
      cursor: default;

      @include theme.apply(background-color, foreground-lightest);

      textarea,
      input {
        @include theme.apply(color, foreground-disabled);

        &::placeholder {
          @include theme.apply(color, foreground-disabled);
        }
      }

      .icon {
        @include theme.apply(color, foreground-disabled);
      }
    }
  }

  &.error {
    .input {
      @include theme.apply(border-color, error-default);
    }

    .icon {
      @include theme.apply(color, error-default);
    }
  }

  &.warning {
    .input {
      @include theme.apply(border-color, warning-darkest);
    }

    .icon {
      @include theme.apply(color, warning-darkest);
    }
  }

  &.info {
    .input {
      @include theme.apply(border-color, info-lightest);
    }

    .icon {
      @include theme.apply(color, action-default);
    }
  }

  &.autoFilled {
    .input {
      @include theme.apply(background-color, action-lightest);
    }
  }

  &.prefilledDisabled {
    .input {
      @include theme.apply(background-color, foreground-lightest);

      .icon {
        @include theme.apply(color, foreground-muted);
      }

      input {
        @include theme.apply('color', foreground-muted);
      }
    }
  }

  &.isFocused {
    .input {
      @include theme.apply(border-color, foreground-default);
    }
  }
}
