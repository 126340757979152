@use 'theme';
@use 'mixins';
@use 'designSystem';
@use 'sass:color';

.dialogOverlay {
  background-color: rgb(224 226 229 / 0.8);
  backdrop-filter: blur(8px);
  display: grid;
  place-items: center;
  transition-property: opacity;
  z-index: 50;

  &[data-state='open'],
  &[data-state='close'] {
    transition-duration: 250ms;
  }

  &[data-state='initial'],
  &[data-state='close'] {
    opacity: 0;
  }
}

.dialog {
  padding: 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 480px;
  width: calc(100% - 32px);
  max-height: 75%;
  overflow: auto;

  @include theme.apply(background-color, foreground-on-foreground);
  @include mixins.scrollbars;

  .heading {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;

    & > h2 {
      @include designSystem.textStyleHeadingSmall18;
    }

    .closeButton {
      @include mixins.cleanButton;
      @include mixins.svgSize('L');
      @include theme.apply(color, action-default);

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .dialogActions {
    display: flex;
    flex-direction: column;
    gap: 8px;

    & > * {
      width: 100%;
      min-width: 0;
    }
  }

  &.small {
    width: 328px;
  }

  &.large {
    max-width: 900px;
    max-height: 750px;
  }

  &.fullscreen {
    max-width: unset;
    max-height: unset;
    width: 100vw;
    height: 100vh;
  }
}
