@use 'designSystem';
@use 'responsive';
@use 'theme';
@use '~/components/ContentModule/variants/variants';

.halfVideoRoundedCorners {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  align-items: center;
  border-radius: 30px;

  @include variants.backgroundTheme;
  @include variants.sizeTheme;
  @include variants.orderMobile;

  &.white,
  &.transparent {
    background: none;

    @include responsive.mobile {
      padding: 36px 0;
    }
  }

  @include responsive.ltLargeDesktop {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
  }

  @include responsive.mobile {
    padding: 48px 24px;
  }

  .videoContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
  }

  .slotsWrapper {
    padding: 0 64px;

    @include responsive.ltLargeDesktop {
      padding: 0 32px;
    }

    @include responsive.ltLargeDesktop {
      padding: 0 0 32px;
    }
  }

  &.reverse {
    > :first-child {
      grid-column: 2;
      grid-row: 1;
    }

    > :nth-child(2) {
      grid-column: 1;
      grid-row: 1;
    }

    @include responsive.ltLargeDesktop {
      > :first-child {
        grid-column: 1;
        grid-row: 2;
      }

      > :nth-child(2) {
        grid-column: 1;
        grid-row: 1;
      }

      .slotsWrapper {
        padding: 32px 0 0;
      }
    }
  }

  &.reverseMobile {
    @include responsive.ltLargeDesktop {
      > :first-child {
        grid-row: 2;
        grid-column: 1;
      }

      > :nth-child(2) {
        grid-row: 1;
        grid-column: 1;
      }
    }
  }

  @include responsive.mobile {
    &.imageAbove {
      &,
      &.reverse,
      &.reverseMobile {
        > :first-child {
          padding: 32px 0 0;
        }
      }
    }

    &.imageBelow {
      &,
      &.reverse,
      &.reverseMobile {
        > :first-child {
          padding: 0 0 32px;
        }
      }
    }
  }
}
