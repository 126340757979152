@use 'responsive';
@use '~/components/ContentModule/variants/variants';

.halfImageFullBleed {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  align-items: center;
  border-radius: 30px;

  @include variants.backgroundTheme;
  @include variants.sizeTheme;
  @include variants.orderMobile;

  @include responsive.mobile {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
  }

  .image {
    border-radius: 0 30px 30px 0;
    object-fit: cover;
    aspect-ratio: 4 / 3;

    @include responsive.mobile {
      border-radius: 0 0 30px 30px;
    }
  }

  &.reverse {
    @include responsive.gtMobile {
      .image {
        border-radius: 30px 0 0 30px;
      }

      > :first-child {
        grid-column: 2;
        grid-row: 1;
      }

      > :nth-child(2) {
        grid-column: 1;
        grid-row: 1;
      }
    }
  }

  &.reverseMobile {
    @include responsive.mobile {
      .image {
        border-radius: 30px 30px 0 0;
      }

      > :first-child {
        grid-row: 2;
        grid-column: 1;
      }

      > :nth-child(2) {
        grid-row: 1;
        grid-column: 1;
      }
    }
  }

  .slotsWrapper {
    padding: 64px;

    @include responsive.mobile {
      padding: 48px 24px;
    }
  }

  @include responsive.mobile {
    &.imageAbove {
      &,
      &.reverse,
      &.reverseMobile {
        .image {
          border-radius: 30px 30px 0 0;
        }
      }
    }

    &.imageBelow {
      &,
      &.reverse,
      &.reverseMobile {
        .image {
          border-radius: 0 0 30px 30px;
        }
      }
    }
  }
}
