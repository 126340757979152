@use 'responsive';
@use '~/components/ContentModule/variants/variants';

.halfImageForm {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  align-items: center;
  border-radius: 30px;

  @include variants.backgroundTheme;
  @include variants.sizeTheme;

  @include responsive.mobile {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
  }

  &.white {
    @include responsive.mobile {
      background: none;
    }
  }

  .image {
    border-radius: 0 30px 30px 0;
    height: 100%;
    aspect-ratio: 4 / 3;
    object-fit: cover;

    @include responsive.mobile {
      border-radius: 0 0 30px 30px;
    }
  }

  .slotsWrapper {
    padding: 64px;

    @include responsive.mobile {
      padding: 48px 24px;
    }
  }

  .side {
    width: 100%;
    position: relative;
    height: 100%;

    @include responsive.mobile {
      height: auto;
    }

    .imageWrapper {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;

      .image {
        border-radius: 0 30px 30px 0;
        height: 100%;
        aspect-ratio: 4 / 3;
        object-fit: cover;

        @include responsive.mobile {
          display: none;
        }
      }
    }

    .formWrapper {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 64px;

      @include responsive.mobile {
        padding: 24px 12px;
      }
    }
  }

  &.reverse {
    @include responsive.gtMobile {
      .side .imageWrapper .image {
        border-radius: 30px 0 0 30px;
      }

      > :first-child {
        grid-column: 2;
        grid-row: 1;
      }

      > :nth-child(2) {
        grid-column: 1;
        grid-row: 1;
      }
    }
  }

  &.reverseMobile {
    @include responsive.mobile {
      .side .imageWrapper .image {
        border-radius: 30px 30px 0 0;
      }

      > :first-child {
        grid-row: 2;
        grid-column: 1;
      }

      > :nth-child(2) {
        grid-row: 1;
        grid-column: 1;
      }
    }
  }
}
