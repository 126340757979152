@use 'responsive';
@use 'theme';
@use 'mixins';

.imageOrPlaceholder {
  width: 100%;
  height: 100%;
  overflow: hidden;

  @include responsive.mobile {
    aspect-ratio: 4 / 3;
  }

  .placeholder {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    @include theme.apply(background, neutral-grey-95);
  }

  img {
    width: 100%;
    height: 100%;
  }
}
