@use 'designSystem';
@use 'responsive';
@use 'theme';
@use '~/components/ContentModule/variants/variants';

.halfImageRoundedCorners {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  align-items: center;
  border-radius: 30px;

  @include variants.backgroundTheme;
  @include variants.sizeTheme;
  @include variants.orderMobile;

  &.white,
  &.transparent {
    background: none;

    @include responsive.mobile {
      padding: 36px 0;
    }
  }

  @include responsive.ltLargeDesktop {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
  }

  @include responsive.mobile {
    padding: 48px 24px;
  }

  .imageContainer {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .image {
      border-radius: 30px;
      width: 100%;
      height: auto;
      aspect-ratio: 4 / 3;
      object-fit: cover;

      @include responsive.ltLargeDesktop {
        max-width: 750px;
      }
    }
  }

  .slotsWrapper {
    padding: 0 64px;

    @include responsive.ltLargeDesktop {
      padding: 0 32px;
    }

    @include responsive.ltLargeDesktop {
      padding: 0 0 32px;
    }
  }

  &.imageOverflow {
    border-radius: 0;
    min-height: 510px;

    > :nth-child(2) {
      border-radius: 30px;
      position: relative;
      padding: 30px;

      @include theme.apply(background-color, foreground-disabled);

      @include responsive.ltLargeDesktop {
        aspect-ratio: 4 / 3;
      }

      @include responsive.ltDesktop {
        padding: 16px;
      }

      .image {
        border-radius: 16px;
        aspect-ratio: unset;
        position: absolute;
        object-position: top;
        width: 185%;
        height: unset;
        min-height: 100%;
        max-width: unset;
        overflow: hidden;
      }
    }

    &.reverse {
      > :nth-child(2) {
        .image {
          right: 30px;
          object-position: top right;

          @include responsive.ltDesktop {
            right: 16px;
          }
        }
      }
    }

    &:not(.reverse) {
      > :nth-child(2) {
        .image {
          object-position: top left;
        }
      }
    }

    &.white,
    &.transparent {
      > :nth-child(2) {
        @include designSystem.effectShadowS;
      }
    }
  }

  &.reverse {
    > :first-child {
      grid-column: 2;
      grid-row: 1;
    }

    > :nth-child(2) {
      grid-column: 1;
      grid-row: 1;
    }

    @include responsive.ltLargeDesktop {
      > :first-child {
        grid-column: 1;
        grid-row: 2;
      }

      > :nth-child(2) {
        grid-column: 1;
        grid-row: 1;
      }

      .slotsWrapper {
        padding: 32px 0 0;
      }
    }
  }

  &.reverseMobile {
    @include responsive.ltLargeDesktop {
      > :first-child {
        grid-row: 2;
        grid-column: 1;
      }

      > :nth-child(2) {
        grid-row: 1;
        grid-column: 1;
      }
    }
  }

  @include responsive.mobile {
    &.imageAbove {
      &,
      &.reverse,
      &.reverseMobile {
        > :first-child {
          padding: 32px 0 0;
        }
      }
    }

    &.imageBelow {
      &,
      &.reverse,
      &.reverseMobile {
        > :first-child {
          padding: 0 0 32px;
        }
      }
    }
  }
}
