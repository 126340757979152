@use 'mixins';
@use 'designSystem';
@use 'theme';
@use 'responsive';

.tooltip {
  position: relative;
  width: 100%;
  padding: 18px 16px;
  border-radius: 6px;
  z-index: 60;

  @include theme.apply(background-color, foreground-default);
  @include theme.apply(color, foreground-on-foreground);

  .contentWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 8px;
    z-index: 10;

    @include designSystem.textStyleBodySmall14;

    .close {
      display: none;

      @include responsive.mobile {
        display: inherit;

        @include mixins.cleanButton;
        @include theme.apply(color, foreground-on-foreground);

        position: absolute;
        top: 0;
        right: 0;
        padding: 16px 16px 16px 8px;
      }
    }

    .content {
      padding: 2px 0;
    }
  }

  .arrow {
    transform: rotate(45deg);
    height: 12px;
    width: 12px;
    z-index: 1;

    @include theme.apply(background-color, foreground-default);
  }
}
