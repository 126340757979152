@use 'responsive';
@use 'theme';

@mixin backgroundTheme {
  &.white {
    @include theme.apply(background, neutral-white);
  }

  &.foregroundSubtle {
    @include theme.apply(background, foreground-subtle);
  }

  &.brand {
    @include theme.apply(background, brand-default-or-gradient);
  }

  &.foreground {
    @include theme.apply(background, foreground-default);
  }

  &.action {
    @include theme.apply(background, action-default);
  }

  &.accent1 {
    @include theme.apply(background, accent-1-default);
  }

  &.accent2 {
    @include theme.apply(background, accent-2-default);
  }

  &.transparent {
    background: transparent;
  }

  &.actionLight {
    @include theme.apply(background, action-light-default);
  }

  &.whiteLightButton {
    @include theme.apply(background, neutral-white);
  }

  &.brandLighten {
    @include theme.apply(background, brand-lighten);
  }
}

@mixin sizeTheme {
  &.small {
    height: auto;
  }

  &.medium {
    min-height: 715px;

    @include responsive.mobile {
      min-height: unset;
    }
  }

  &.large {
    min-height: 900px;

    @include responsive.mobile {
      min-height: unset;
    }
  }
}

@mixin orderMobile {
  @include responsive.mobile {
    &.imageAbove {
      &,
      &.reverse,
      &.reverseMobile {
        > :first-child {
          grid-row: 2;
          grid-column: 1;
        }

        > :nth-child(2) {
          grid-row: 1;
          grid-column: 1;
        }
      }
    }

    &.imageBelow {
      &,
      &.reverse,
      &.reverseMobile {
        > :first-child {
          grid-row: 1;
          grid-column: 1;
        }

        > :nth-child(2) {
          grid-row: 2;
          grid-column: 1;
        }
      }
    }
  }
}

@mixin textColorTheme {
  &.white,
  &.transparent,
  &.whiteLightButton,
  &.brandLighten {
    @include theme.apply(color, foreground-default);
    @include anchorColor(action-default);

    p {
      @include theme.apply(color, foreground-muted);
    }
  }

  &.foregroundSubtle {
    @include theme.apply(color, foreground-default);
    @include anchorColor(action-default);
  }

  &.brand {
    @include theme.apply(color, brand-on-brand);
    @include anchorColor(brand-on-brand);
  }

  &.foreground {
    @include theme.apply(color, foreground-on-foreground);
    @include anchorColor(foreground-on-foreground);
  }

  &.action {
    @include theme.apply(color, action-on-action);
    @include anchorColor(action-on-action);
  }

  &.accent1 {
    @include theme.apply(color, accent-1-on-accent-1);
    @include anchorColor(accent-1-on-accent-1);
  }

  &.accent2 {
    @include theme.apply(color, accent-2-on-accent-2);
    @include anchorColor(accent-2-on-accent-2);
  }

  &.actionLight {
    @include theme.apply(color, foreground-default);
    @include anchorColor(action-default);

    p {
      @include theme.apply(color, foreground-default);
    }
  }
}

@mixin anchorColor($color) {
  a {
    @include theme.apply(color, $color);
  }

  :global {
    .termsLink {
      @include theme.apply(color, $color);
    }
  }
}
