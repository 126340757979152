@use '~/components/ContentModule/variants/variants';
@use 'designSystem';

.slotList {
  @include designSystem.textStyleBodyLarge24;
  @include variants.textColorTheme;

  p {
    @include designSystem.textStyleBodyLarge24;
  }

  ul,
  ol {
    margin: 0;
  }
}
