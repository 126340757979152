@use 'theme';
@use 'mixins';
@use 'designSystem';

.dropdown {
  width: 100%;
  border-radius: 12px;
  max-height: 300px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  z-index: 5;

  @include theme.apply(background-color, neutral-white);
  @include designSystem.shadowTheme80;
  @include mixins.scrollbars;

  .selectOption {
    @include mixins.cleanButton;
    @include designSystem.textStyleBodySmall16;
    @include theme.apply(color, foreground-default);

    padding: 16px;
    text-align: left;

    &:disabled {
      cursor: default;

      @include theme.apply(color, foreground-disabled);
    }

    &:hover:not(:disabled),
    &.isActive {
      @include theme.apply(background-color, action-lightest);
    }

    &.isSelected:not(:disabled) {
      @include theme.apply(background-color, action-light-default);
    }
  }
}
