@use 'mixins';
@use 'designSystem';
@use 'theme';

$tooltipPadding: 16px;

.fieldLabel {
  position: relative;
  display: flex;
  gap: $tooltipPadding;
  justify-content: space-between;

  .fieldLabelText {
    display: block;
    margin: 0;
    cursor: default;
    transition: 0.2s all ease;

    // <legend/> fix
    float: left;
    width: 100%;

    @include designSystem.textStyleHeadingSmall16;
    @include theme.apply(color, foreground-default);
  }

  .tooltip {
    @include mixins.cleanButton;

    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    right: 0;
    padding: $tooltipPadding;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
