@use 'responsive';

.slotButtons {
  display: flex;
  flex-direction: row;
  column-gap: 32px;
  margin-top: 20px;

  a {
    width: max-content;

    .screenReaderText {
      border: 0;
      clip: rect(1px, 1px, 1px, 1px);
      clip-path: inset(50%);
      height: 1px;
      margin: 0;
      padding: 0;
      overflow: hidden;
      position: absolute !important; // stylelint-disable-line
      width: 1px;
      white-space: nowrap;
    }
  }

  @include responsive.mobile {
    flex-direction: column;
    row-gap: 32px;
    width: 100%;

    a {
      width: 100%;

      @include responsive.smallPhone {
        padding-left: 16px;
        padding-right: 16px;
      }

      @include responsive.tinyPhone {
        font-size: 21px;
      }
    }
  }
}
