@use 'designSystem';
@use 'theme';

.fieldValidationText {
  display: flex;
  gap: 8px;

  @include designSystem.textStyleBodySmall16;
  @include theme.apply(color, foreground-default);

  & > svg {
    flex-shrink: 0;
  }

  & > p {
    min-width: 0;
    width: 100%;
    word-wrap: break-word;
  }

  &.error {
    & > svg {
      @include theme.apply(color, error-default);
    }
  }

  &.warning {
    & > svg {
      @include theme.apply(color, warning-darkest);
    }
  }

  &.info {
    & > svg {
      @include theme.apply(color, action-default);
    }
  }
}
