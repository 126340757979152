@use 'mixins';

.slots {
  display: flex;
  flex-direction: column;
  row-gap: 12px;

  // Override highlight to italics
  em {
    background: none;
    font-style: italic;
  }

  &.left {
    align-items: flex-start;

    p {
      text-align: left;
    }

    @include mixins.headings {
      text-align: left;
    }
  }

  &.center {
    align-items: center;

    p {
      text-align: center;
    }

    @include mixins.headings {
      text-align: center;
    }
  }

  &.right {
    align-items: flex-end;

    p {
      text-align: right;
    }

    @include mixins.headings {
      text-align: right;
    }
  }
}
