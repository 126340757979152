@use 'responsive';
@use '~/components/ContentModule/variants/variants';

.fullImageForm {
  display: grid;
  grid-template-columns: 1fr repeat(2, 224px) 1fr;
  grid-template-rows: 1fr;
  align-items: center;
  border-radius: 30px;
  position: relative;

  @include variants.backgroundTheme;

  &.small {
    min-height: 564px;

    @include responsive.mobile {
      min-height: unset;
    }
  }

  &.medium {
    min-height: 696px;

    @include responsive.mobile {
      min-height: unset;
    }
  }

  &.large {
    min-height: 692px;

    @include responsive.mobile {
      min-height: unset;
    }
  }

  @include responsive.mobile {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(2, auto);
  }

  &.white {
    @include responsive.mobile {
      background: none;
    }
  }

  .slotsWrapper {
    padding-left: 64px;
    position: relative;
    z-index: 1;

    @include responsive.ltLargeDesktop {
      grid-column: span 2;
    }

    @include responsive.mobile {
      padding: 48px;
    }
  }

  .side {
    width: 100%;
    height: 100%;
    grid-column: 3 / span 4;

    @include responsive.mobile {
      height: auto;
    }

    @include responsive.ltLargeDesktop {
      grid-column: span 2;
    }

    .imageWrapper {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;

      & > div {
        display: flex;
        justify-content: flex-end;

        .image {
          border-radius: 30px;
          height: 100%;
          max-width: 50%;
          aspect-ratio: 4 / 3;
          object-fit: contain;
          object-position: bottom;
        }
      }

      @include responsive.ltLargeDesktop {
        display: none;
      }
    }

    .formWrapper {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 64px 64px 64px 32px;

      @include responsive.ltDesktop {
        & > div {
          max-width: none;
        }
      }

      @include responsive.mobile {
        padding: 24px;
      }
    }
  }

  &.reverse {
    @include responsive.gtMobile {
      > .slotsWrapper {
        padding-right: 64px;
        grid-row: 1;
        grid-column: 4;

        @include responsive.ltLargeDesktop {
          grid-column: 3 / span 2;
        }
      }

      > .side {
        grid-row: 1;
        grid-column: 1 / span 2;

        @include responsive.gtMobile {
          > .formWrapper {
            padding-right: 32px;
            padding-left: 64px;
          }
        }

        @include responsive.ltLargeDesktop {
          grid-column: 1 / span 2;
        }
      }
    }
  }

  &.reverseMobile {
    @include responsive.mobile {
      .side .imageWrapper .image {
        border-radius: 30px 30px 0 0;
      }

      > :first-child {
        grid-row: 2;
        grid-column: 1;
      }

      > :nth-child(2) {
        grid-row: 1;
        grid-column: 1;
      }
    }
  }
}
