@use 'designSystem';
@use 'responsive';
@use 'theme';
@use '~/components/ContentModule/variants/variants';

.videoWrapper {
  width: 100%;
  height: 100%;

  > div,
  > div > video {
    border-radius: 30px;
    width: 100%;
    height: fit-content;
    aspect-ratio: 4 / 3;
    object-fit: cover;

    @include responsive.ltLargeDesktop {
      max-width: 750px;
    }
  }

  .image {
    display: flex;
    align-items: center;
    justify-content: center;
    height: fit-content;

    // hides YouTube overlay so we can use custom play button
    // https://stackoverflow.com/questions/18342536/how-to-hide-the-bar-at-the-top-of-youtube-even-when-mouse-hovers-over-it
    > div {
      overflow: hidden;
      width: 100%;
      border-radius: 30px;
      aspect-ratio: 16/9;
      pointer-events: none;
    }

    > div iframe {
      // Extend it beyond the viewport to hide title and yt watermark
      width: 300%;
      height: 100%;
      margin-left: -100%;
    }
  }

  .buttonOverride {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: fit-content;
    padding: 28px;
    height: auto;
    opacity: 0;
    transition: opacity ease-in-out 300ms;
  }

  &:hover {
    .buttonOverride {
      opacity: 1;
    }
  }

  .paused {
    opacity: 1;
  }
}
