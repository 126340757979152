@use 'responsive';
@use '~/components/ContentModule/variants/variants';

.noImage {
  padding: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;

  @include variants.backgroundTheme;
  @include variants.sizeTheme;

  @include responsive.mobile {
    padding: 48px 24px;

    &.transparent {
      padding: 24px 0;
    }
  }

  &.noPadding {
    @include responsive.mobile {
      border-radius: 0;
      margin-left: -24px;
      margin-right: -24px;
    }
  }
}
