@use 'responsive';
@use 'designSystem';
@use 'theme';
@use '~/components/ContentModule/variants/variants';

.slotParagraph {
  @include designSystem.textStyleBodyLarge24;
  @include variants.textColorTheme;

  p {
    @include designSystem.textStyleBodyLarge24;
  }
}

[data-site='vicParkCity'] {
  .slotParagraph {
    &.transparent {
      p {
        @include theme.apply(color, foreground-default);
      }
    }
  }
}
